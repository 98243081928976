import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import httpClient from '@/services/api.service'

import vuetify from '@/plugins/vuetify'
import '@/plugins/vue-progressbar'
import '@/plugins/vue-modal'

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

httpClient.init(app)
