import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AuthService from '@/services/auth.service'

import authRoutes from '@/router/modules/auth'
import companyRoutes from '@/router/modules/company'

Vue.use(VueRouter)

const routes = [...authRoutes, ...companyRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => AuthService.ensureAuthenticated(store, to, from, next))
router.beforeEach((to, from, next) => AuthService.ensureUnauthenticated(store, to, from, next))

export default router
