export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      authenticatedOnly: false
    }
  },
  {
    path: '/',
    alias: '/companies',
    name: 'companies',
    component: () => import('@/views/Companies.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/reward-suggestions',
    name: 'reward-suggestions',
    component: () => import('@/views/RewardSuggestions.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/system-messages',
    name: 'system-messages',
    component: () => import('@/views/SystemMessages.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import('@/views/Plans.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/views/Coupons.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/questions',
    name: 'questions',
    component: () => import('@/views/Questions.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
