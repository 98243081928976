import moment from 'moment'

import httpClient from '@/services/api.service'
import AuthService from '@/services/auth.service'

import {
  AUTH_REQUEST,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR
} from '@/store/types'

const state = {
  authToken: AuthService.getAuthToken(),
  authTokenExpiry: AuthService.getAuthTokenExpiry(),
  status: null,
  userStatus: null,
  user: null
}

const getters = {
  isAuthenticated: state => !!state.authToken && moment().isBefore(state.authTokenExpiry)
}

const actions = {
  async [AUTH_REQUEST]({ commit }, user) {
    commit(AUTH_REQUEST)

    try {
      const { data } = await httpClient.save('login', user)
      commit(AUTH_SUCCESS, data.data)
    } catch (error) {
      AuthService.removeAuthToken()
      commit(AUTH_ERROR, error)
      Promise.reject(error)
    }
  },
  async [GET_USER_REQUEST]({ commit }) {
    commit(GET_USER_REQUEST)

    try {
      const { data } = await httpClient.get('users/me')
      commit(GET_USER_SUCCESS, data.data)
    } catch (error) {
      commit(GET_USER_ERROR, error)
      Promise.reject(error)
    }
  }
}

const mutations = {
  [AUTH_REQUEST](state) {
    state.status = 'loading'
  },
  [AUTH_SUCCESS](state, { accessToken, expiresAt }) {
    AuthService.setAuthToken(accessToken)
    AuthService.setAuthTokenExpiry(expiresAt)
    httpClient.setAuthorizationHeader(accessToken)
    state.authToken = accessToken
    state.authTokenExpiry = expiresAt
    state.status = 'success'
  },
  [AUTH_ERROR](state) {
    state.status = 'error'
  },
  [AUTH_LOGOUT](state) {
    AuthService.removeAuthToken()
    AuthService.removeAuthTokenExpiry()
    state.user = null
    state.authToken = ''
    state.status = null
    state.userStatus = null
  },
  [GET_USER_REQUEST](state) {
    state.userStatus = 'loading'
  },
  [GET_USER_SUCCESS](state, user) {
    state.userStatus = 'success'
    state.user = user
  },
  [GET_USER_ERROR](state) {
    state.userStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
