<template>
  <v-navigation-drawer class="app-main-navigation" app permanent>
    <v-list class="app-main-navigation-list">
      <div class="app-main-navigation-list-items">
        <div class="app-logo pt-2 pb-5 px-4">
          <router-link to="/">
            <img src="@/assets/images/logo.svg" alt="beeward" class="logo" />
          </router-link>
        </div>
        <v-list-item-group color="primary">
          <v-list-item v-for="menuItem in menuList" :key="menuItem.id" :to="menuItem.link">
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'AppMainNavigation',
  data() {
    return {
      menuList: [
        {
          id: 1,
          name: 'Cégek',
          icon: 'mdi-account-group',
          link: '/'
        },
        {
          id: 2,
          name: 'Jutalom javaslatok',
          icon: 'mdi-gift',
          link: '/reward-suggestions'
        },
        {
          id: 3,
          name: 'Rendszer üzenetek',
          icon: 'mdi-message-text',
          link: '/system-messages'
        },
        {
          id: 4,
          name: 'Csomagok',
          icon: 'mdi-package-variant-closed',
          link: '/plans'
        },
        {
          id: 5,
          name: 'Kuponok',
          icon: 'mdi-ticket-percent',
          link: '/coupons'
        },
        {
          id: 6,
          name: 'Megkérdezések',
          icon: 'mdi-chat-question',
          link: '/questions'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.app-logo {
  a {
    text-decoration: none;
  }
}

.app-main-navigation-list {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .v-list-item {
    .v-list-item__icon {
      margin-right: 15px;
    }
  }

  .v-list-item {
    &:last-child {
      flex: 0;

      .v-list-item__icon {
        align-self: center;
      }
    }
  }
}

.v-list-group {
  &--active {
    background-color: #263237;
  }
}

.v-list-group__items {
  .v-list-item {
    &--active {
      &::before {
        background-color: transparent;
      }
    }
  }
}
</style>
