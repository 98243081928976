import httpClient from '@/services/api.service'

import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_ERROR,
  SET_COMPANIES,
  GET_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY
} from '@/store/types'

const state = {
  companies: [],
  status: null,
  error: null
}

const actions = {
  async [GET_COMPANIES_REQUEST]({ commit }) {
    commit(GET_COMPANIES_REQUEST)

    try {
      const { data } = await httpClient.get(`companies?includes=billing`)
      commit(SET_COMPANIES, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_COMPANIES_ERROR, error)
      return Promise.reject(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [GET_COMPANY]({ commit }, { companyId }) {
    try {
      const { data } = await httpClient.get(`companies/${companyId}`)
      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CREATE_COMPANY]({ commit }, company) {
    try {
      await httpClient.save(`companies`, company)
      commit(CREATE_COMPANY, company)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_COMPANY]({ commit }, { companyId, payload }) {
    try {
      const { data } = await httpClient.patch(`companies`, companyId, payload)
      commit(UPDATE_COMPANY, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_COMPANY]({ commit }, companyId) {
    try {
      await httpClient.delete(`companies`, companyId)
      commit(DELETE_COMPANY, companyId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_COMPANIES_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_COMPANIES_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_COMPANIES](state, data) {
    state.status = 'success'
    state.companies = data
  },
  [CREATE_COMPANY](state, company) {
    state.companies.push(company)
  },
  [UPDATE_COMPANY](state, company) {
    const index = state.companies.findIndex(i => i.id === company.id)
    state.companies.splice(index, 1, company)
  },
  [DELETE_COMPANY](state, companyId) {
    const index = state.companies.findIndex(i => i.id === companyId)
    state.companies.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
