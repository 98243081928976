import httpClient from '@/services/api.service'

import {
  GET_REWARD_SUGGESTIONS_REQUEST,
  GET_REWARD_CATEGORIES_REQUEST,
  GET_REWARD_CATEGORIES_ERROR,
  GET_REWARD_SUGGESTIONS_ERROR,
  SET_REWARD_SUGGESTIONS,
  SET_REWARD_CATEGORIES,
  GET_REWARD_SUGGESTION,
  CREATE_REWARD_SUGGESTION,
  UPDATE_REWARD_SUGGESTION,
  DELETE_REWARD_SUGGESTION
} from '@/store/types'

const state = {
  rewards: [],
  categories: [],
  status: null,
  error: null
}

const actions = {
  async [GET_REWARD_SUGGESTIONS_REQUEST]({ commit }) {
    commit(GET_REWARD_SUGGESTIONS_REQUEST)

    try {
      const { data } = await httpClient.get('system-rewards')
      commit(SET_REWARD_SUGGESTIONS, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_REWARD_SUGGESTIONS_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [GET_REWARD_CATEGORIES_REQUEST]({ commit }) {
    commit(GET_REWARD_CATEGORIES_REQUEST)

    try {
      const { data } = await httpClient.get('reward-categories')
      commit(SET_REWARD_CATEGORIES, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_REWARD_CATEGORIES_ERROR, error)
      return Promise.reject(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [GET_REWARD_SUGGESTION]({ commit }, { rewardId }) {
    try {
      const { data } = await httpClient.get(`system-rewards/${rewardId}`)
      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CREATE_REWARD_SUGGESTION]({ commit }, reward) {
    try {
      const { data } = await httpClient.save('system-rewards', reward)
      commit(CREATE_REWARD_SUGGESTION, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_REWARD_SUGGESTION]({ commit }, { rewardId, payload }) {
    try {
      const { data } = await httpClient.patch('system-rewards', rewardId, payload)
      commit(UPDATE_REWARD_SUGGESTION, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_REWARD_SUGGESTION]({ commit }, rewardId) {
    try {
      await httpClient.delete('system-rewards', rewardId)
      commit(DELETE_REWARD_SUGGESTION, rewardId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_REWARD_SUGGESTIONS_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_REWARD_CATEGORIES_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_REWARD_SUGGESTIONS_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [GET_REWARD_CATEGORIES_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_REWARD_SUGGESTIONS](state, data) {
    state.status = 'success'
    state.rewards = data
  },
  [SET_REWARD_CATEGORIES](state, data) {
    state.status = 'success'
    state.categories = data
  },
  [CREATE_REWARD_SUGGESTION](state, reward) {
    state.rewards.push(reward)
  },
  [UPDATE_REWARD_SUGGESTION](state, reward) {
    const index = state.rewards.findIndex(i => i.id === reward.id)
    state.rewards[index] = Object.assign(state.rewards[index], reward)
  },
  [DELETE_REWARD_SUGGESTION](state, rewardId) {
    const index = state.rewards.findIndex(i => i.id === rewardId)
    state.rewards.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
