import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import upload from '@/store/modules/upload'
import companies from '@/store/modules/companies'
import rewardSuggestions from '@/store/modules/rewardSuggestions'
import systemMessages from '@/store/modules/systemMessages'
import plans from '@/store/modules/plans'
import coupons from '@/store/modules/coupons'
import questions from '@/store/modules/questions'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    upload,
    companies,
    rewardSuggestions,
    systemMessages,
    plans,
    coupons,
    questions
  },
  plugins: [
    createPersistedState({
      paths: ['auth.user']
    })
  ],
  strict: debug
})
