import httpClient from '@/services/api.service'
import { FILE_UPLOAD } from '@/store/types'

// const state = {
//   isLoading: false
// }

const actions = {
  async [FILE_UPLOAD](state, formData) {
    try {
      const { data } = await httpClient.save('images/upload', formData)

      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  actions
}
