export default [
  {
    path: '/',
    alias: '/companies',
    name: 'companies',
    component: () => import('@/views/Companies.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/companies/:id',
    name: 'company',
    component: () => import('@/views/Company.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
