import httpClient from '@/services/api.service'

import {
  GET_SYSTEM_MESSAGES_REQUEST,
  GET_SYSTEM_MESSAGES_ERROR,
  SET_SYSTEM_MESSAGES,
  GET_SYSTEM_MESSAGE,
  CREATE_SYSTEM_MESSAGE,
  UPDATE_SYSTEM_MESSAGE,
  DELETE_SYSTEM_MESSAGE
} from '@/store/types'

const state = {
  resources: [],
  status: null,
  error: null
}

const apiEndpoint = 'system-messages'

const actions = {
  async [GET_SYSTEM_MESSAGES_REQUEST]({ commit }) {
    commit(GET_SYSTEM_MESSAGES_REQUEST)

    try {
      const { data } = await httpClient.get(apiEndpoint)
      commit(SET_SYSTEM_MESSAGES, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_SYSTEM_MESSAGES_ERROR, error)
      return Promise.reject(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [GET_SYSTEM_MESSAGE]({ commit }, { resourceId }) {
    try {
      const { data } = await httpClient.get(`${apiEndpoint}/${resourceId}`)
      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [CREATE_SYSTEM_MESSAGE]({ commit }, resource) {
    try {
      const { data } = await httpClient.save(apiEndpoint, resource)
      commit(CREATE_SYSTEM_MESSAGE, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_SYSTEM_MESSAGE]({ commit }, { resourceId, payload }) {
    try {
      const { data } = await httpClient.patch(apiEndpoint, resourceId, payload)
      commit(UPDATE_SYSTEM_MESSAGE, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_SYSTEM_MESSAGE]({ commit }, resourceId) {
    try {
      await httpClient.delete(apiEndpoint, resourceId)
      commit(DELETE_SYSTEM_MESSAGE, resourceId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_SYSTEM_MESSAGES_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_SYSTEM_MESSAGES_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_SYSTEM_MESSAGES](state, data) {
    state.status = 'success'
    state.resources = data
  },
  [CREATE_SYSTEM_MESSAGE](state, resource) {
    state.resources.push(resource)
  },
  [UPDATE_SYSTEM_MESSAGE](state, resource) {
    const index = state.resources.findIndex(i => i.id === resource.id)
    state.resources.splice(index, 1, resource)
  },
  [DELETE_SYSTEM_MESSAGE](state, resourceId) {
    const index = state.resources.findIndex(i => i.id === resourceId)
    state.resources.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
