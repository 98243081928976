import httpClient from '@/services/api.service'

import { GET_PLANS_REQUEST, GET_PLANS_ERROR, SET_PLANS, UPDATE_PLAN, CREATE_PLAN, DELETE_PLAN } from '@/store/types'

const state = {
  plans: [],
  status: null,
  error: null
}

const actions = {
  async [GET_PLANS_REQUEST]({ commit }) {
    commit(GET_PLANS_REQUEST)

    try {
      const { data } = await httpClient.get(`plans`)
      commit(SET_PLANS, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_PLANS_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [CREATE_PLAN]({ commit }, plan) {
    try {
      const { data } = await httpClient.save('plans', plan)
      commit(CREATE_PLAN, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_PLAN]({ commit }, { planId, payload }) {
    try {
      const { data } = await httpClient.patch(`plans`, planId, payload)
      commit(UPDATE_PLAN, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_PLAN]({ commit }, resourceId) {
    try {
      await httpClient.delete('plans', resourceId)
      commit(DELETE_PLAN, resourceId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
  // eslint-disable-next-line no-unused-vars
}

const mutations = {
  [GET_PLANS_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_PLANS_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_PLANS](state, data) {
    state.status = 'success'
    state.plans = data
  },
  [CREATE_PLAN](state, plan) {
    state.plans.push(plan)
  },
  [UPDATE_PLAN](state, plan) {
    const index = state.plans.findIndex(i => i.id === plan.id)
    state.plans[index] = Object.assign(state.plans[index], plan)
  },
  [DELETE_PLAN](state, planId) {
    const index = state.plans.findIndex(i => i.id === planId)
    state.plans.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
