export const AUTH_REQUEST = 'auth_request'
export const AUTH_LOGOUT = 'auth_logout'
export const AUTH_SUCCESS = 'auth_success'
export const AUTH_ERROR = 'auth_error'

export const GET_USER_REQUEST = 'get_user_request'
export const GET_USER_SUCCESS = 'get_user_success'
export const GET_USER_ERROR = 'get_user_error'
export const SET_USER = 'set_user'

export const FILE_UPLOAD = 'file_upload'

export const GET_COMPANIES_REQUEST = 'get_companies_request'
export const GET_COMPANIES_ERROR = 'get_companies_error'
export const SET_COMPANIES = 'set_companies'
export const GET_COMPANY = 'get_company'
export const CREATE_COMPANY = 'create_company'
export const UPDATE_COMPANY = 'update_company'
export const DELETE_COMPANY = 'delete_company'

export const GET_REWARD_SUGGESTIONS_REQUEST = 'get_reward_suggestions_request'
export const GET_REWARD_CATEGORIES_REQUEST = 'get_reward_categories_request'
export const GET_REWARD_SUGGESTIONS_ERROR = 'get_reward_suggestions_error'
export const GET_REWARD_CATEGORIES_ERROR = 'get_reward_categories_error'
export const SET_REWARD_SUGGESTIONS = 'set_reward_suggestions'
export const SET_REWARD_CATEGORIES = 'set_reward_categories'
export const GET_REWARD_SUGGESTION = 'get_reward_suggestion'
export const CREATE_REWARD_SUGGESTION = 'create_reward_suggestion'
export const UPDATE_REWARD_SUGGESTION = 'update_reward_suggestion'
export const DELETE_REWARD_SUGGESTION = 'delete_reward_suggestion'

export const GET_SYSTEM_MESSAGES_REQUEST = 'get_system_messages_request'
export const GET_SYSTEM_MESSAGES_ERROR = 'get_system_messages_error'
export const SET_SYSTEM_MESSAGES = 'set_system_messages'
export const GET_SYSTEM_MESSAGE = 'get_system_message'
export const CREATE_SYSTEM_MESSAGE = 'create_system_message'
export const UPDATE_SYSTEM_MESSAGE = 'update_system_message'
export const DELETE_SYSTEM_MESSAGE = 'delete_system_message'

export const GET_PLANS_REQUEST = 'get_plans_request'
export const SET_PLANS = 'set_plans'
export const GET_PLANS_ERROR = 'get_plans_error'
export const UPDATE_PLAN = 'update_plan'
export const CREATE_PLAN = 'create_plan'
export const DELETE_PLAN = 'delete_plan'

export const GET_COUPONS_REQUEST = 'get_coupons_request'
export const SET_COUPONS = 'set_coupons'
export const GET_COUPONS_ERROR = 'get_coupons_error'
export const UPDATE_COUPON = 'update_coupon'
export const CREATE_COUPON = 'create_coupon'
export const DELETE_COUPON = 'delete_coupon'

export const GET_QUESTIONS_REQUEST = 'get_questions_request'
export const SET_QUESTIONS = 'set_questions'
export const GET_QUESTIONS_ERROR = 'get_questions_error'
export const UPDATE_QUESTION = 'update_question'
export const CREATE_QUESTION = 'create_question'
export const DELETE_QUESTION = 'delete_question'
