<template>
  <v-app-bar app class="app-top-navigation">
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" v-if="user">
          <v-img max-height="25" max-width="25" :src="user.avatar" class="user-avatar"></v-img>
          {{ user.fullName }}
          <v-icon dark color="grey lighten-1" right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title>Kijelentkezés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapState } from 'vuex'

import { AUTH_LOGOUT } from '@/store/types'

import AuthService from '@/services/auth.service'
import httpClient from '@/services/api.service'

export default {
  name: 'AppTopNavigation',
  computed: {
    ...mapState({
      status: state => state.navigation.status,
      user: state => state.auth.user
    })
  },
  methods: {
    async logout() {
      await httpClient.save('logout')
      this.$store.commit(AUTH_LOGOUT)
      this.$router.push({ name: AuthService.LOGIN_ROUTE })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  border-radius: 50%;
  margin-right: 8px;
}
</style>
