<template>
  <v-app>
    <app-main-navigation v-if="isAuthenticated && !isLoading" />
    <app-top-navigation v-if="isAuthenticated && !isLoading" />

    <v-main v-if="!isLoading">
      <router-view />
    </v-main>

    <app-skeleton-loader v-else />

    <vue-progress-bar />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppMainNavigation from '@/components/Navigation/AppMainNavigation.vue'
import AppTopNavigation from '@/components/Navigation/AppTopNavigation.vue'
import AppSkeletonLoader from '@/components/Global/AppSkeletonLoader.vue'

export default {
  name: 'App',
  components: {
    AppMainNavigation,
    AppTopNavigation,
    AppSkeletonLoader
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      status: state => state.auth.userStatus
    }),
    isLoading() {
      return this.status === 'loading'
    }
  }
}
</script>
