import httpClient from '@/services/api.service'

import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_ERROR,
  SET_COUPONS,
  UPDATE_COUPON,
  CREATE_COUPON,
  DELETE_COUPON
} from '@/store/types'

const state = {
  coupons: [],
  status: null,
  error: null
}

const actions = {
  async [GET_COUPONS_REQUEST]({ commit }) {
    commit(GET_COUPONS_REQUEST)

    try {
      const { data } = await httpClient.get(`coupons`)
      commit(SET_COUPONS, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_COUPONS_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [CREATE_COUPON]({ commit }, coupon) {
    try {
      const { data } = await httpClient.save('coupons', coupon)
      commit(CREATE_COUPON, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_COUPON]({ commit }, { couponId, payload }) {
    try {
      const { data } = await httpClient.patch(`coupons`, couponId, payload)
      commit(UPDATE_COUPON, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_COUPON]({ commit }, resourceId) {
    try {
      await httpClient.delete('coupons', resourceId)
      commit(DELETE_COUPON, resourceId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
  // eslint-disable-next-line no-unused-vars
}

const mutations = {
  [GET_COUPONS_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_COUPONS_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_COUPONS](state, data) {
    state.status = 'success'
    state.coupons = data
  },
  [CREATE_COUPON](state, coupon) {
    state.coupons.push(coupon)
  },
  [UPDATE_COUPON](state, coupon) {
    const index = state.coupons.findIndex(i => i.id === coupon.id)
    state.coupons[index] = Object.assign(state.coupons[index], coupon)
  },
  [DELETE_COUPON](state, couponId) {
    const index = state.coupons.findIndex(i => i.id === couponId)
    state.coupons.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
