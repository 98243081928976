<template>
  <div class="app-skeleton-loader">
    <div class="skeleton-loader-main-nav">
      <v-skeleton-loader type="card-heading" tile light></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar" tile light></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar" tile light></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar" tile light></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar" tile light></v-skeleton-loader>
      <v-skeleton-loader type="list-item-avatar" tile light></v-skeleton-loader>
    </div>

    <div class="skeleton-loader-top-nav">
      <v-skeleton-loader type="actions" tile light></v-skeleton-loader>
    </div>

    <div class="skeleton-loader-main"></div>
  </div>
</template>
<script>
export default {
  name: 'AppSkeletonLoader'
}
</script>
<style lang="scss" scoped>
.skeleton-loader-main-nav {
  height: 100vh;
  width: 256px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  border-right: 1px solid #eee;
}

.skeleton-loader-top-nav {
  position: fixed;
  margin-top: 0px;
  transform: translateY(0px);
  left: 256px;
  right: 0;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.skeleton-loader-main {
  padding: 64px 0 0 256px;
  display: flex;
  flex: 1 0 auto;
  max-width: 90%;

  .v-skeleton-loader {
    margin: 40px 0 0 40px;
  }
}
</style>
