import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'

const progressBarOptions = {
  color: '#fecb45',
  failedColor: '#ff6347',
  thickness: '3px'
}

Vue.use(VueProgressBar, progressBarOptions)
