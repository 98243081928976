import httpClient from '@/services/api.service'

import {
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_ERROR,
  SET_QUESTIONS,
  UPDATE_QUESTION,
  CREATE_QUESTION,
  DELETE_QUESTION
} from '@/store/types'

const state = {
  questions: [],
  status: null,
  error: null
}

const actions = {
  async [GET_QUESTIONS_REQUEST]({ commit }) {
    commit(GET_QUESTIONS_REQUEST)

    try {
      const { data } = await httpClient.get(`questions`)
      commit(SET_QUESTIONS, data.data)
      return Promise.resolve(data.data)
    } catch (error) {
      commit(GET_QUESTIONS_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [CREATE_QUESTION]({ commit }, question) {
    try {
      const { data } = await httpClient.save('questions', question)
      commit(CREATE_QUESTION, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [UPDATE_QUESTION]({ commit }, { questionId, payload }) {
    try {
      const { data } = await httpClient.patch(`questions`, questionId, payload)
      commit(UPDATE_QUESTION, data.data)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async [DELETE_QUESTION]({ commit }, resourceId) {
    try {
      await httpClient.delete('questions', resourceId)
      commit(DELETE_QUESTION, resourceId)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [GET_QUESTIONS_REQUEST](state) {
    state.status = 'loading'
  },
  [GET_QUESTIONS_ERROR](state, error) {
    state.status = 'error'
    state.error = error
  },
  [SET_QUESTIONS](state, data) {
    state.status = 'success'
    state.questions = data
  },
  [CREATE_QUESTION](state, question) {
    state.questions.push(question)
  },
  [UPDATE_QUESTION](state, question) {
    const index = state.questions.findIndex(i => i.id === question.id)
    state.questions[index] = Object.assign(state.questions[index], question)
  },
  [DELETE_QUESTION](state, questionId) {
    const index = state.questions.findIndex(i => i.id === questionId)
    state.questions.splice(index, 1)
  }
}

export default {
  state,
  actions,
  mutations
}
