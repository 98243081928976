const LOGIN_ROUTE = 'login'
const HOME_ROUTE = '/'
const AUTH_TOKEN_KEY = 'authToken'
const AUTH_TOKEN_EXPIRY_KEY = 'authTokenExpiry'

const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY) || ''
const setAuthToken = token => localStorage.setItem(AUTH_TOKEN_KEY, token)
const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY)

const getAuthTokenExpiry = () => localStorage.getItem(AUTH_TOKEN_EXPIRY_KEY)
const setAuthTokenExpiry = date => localStorage.setItem(AUTH_TOKEN_EXPIRY_KEY, date)
const removeAuthTokenExpiry = () => localStorage.removeItem(AUTH_TOKEN_EXPIRY_KEY)

const ensureAuthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters

  const canAccessRoute = to.name === LOGIN_ROUTE ? '' : to.name
  const loginCallbackRoute = canAccessRoute
    ? { name: LOGIN_ROUTE }
    : { name: LOGIN_ROUTE, query: { redirect: to.fullPath } }

  if (to.matched.some(record => record.meta.authenticatedOnly) && !isAuthenticated) {
    next(loginCallbackRoute)
  }

  next()
}

const ensureUnauthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters

  if (to.matched.some(record => !record.meta.authenticatedOnly) && isAuthenticated) {
    next(from.matched.length ? false : { name: HOME_ROUTE })
    return
  }

  next()
}

export default {
  LOGIN_ROUTE,
  HOME_ROUTE,
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getAuthTokenExpiry,
  setAuthTokenExpiry,
  removeAuthTokenExpiry,
  ensureAuthenticated,
  ensureUnauthenticated
}
